import React from "react"
import Layout from "../templates/layout"
import Topography from '../components/topography-section'
import Hero from '../components/hero'
import Container from '../components/container'
import Gallery from 'react-photo-gallery';
import { graphql } from 'gatsby';


const Grid = ({ data }) => {
  const pics = data.allPrismicGallery.nodes

  const group = pics[0].data.image_gallery;

 

  const photos = []

  const list = group.map((image_data) => 
        photos.push({src: image_data.image.url, height: image_data.image.dimensions.height, width: image_data.image.dimensions.width}),
    );


  return (
    <Layout
      title="Gallery | Loveless Outdoor Adventures"
      description="Check out pictures from our past bowfishing, catfishing, and duck hunting trips on Kentucky Lake. See what the excitement is all about!"
    >
      <Hero title="image gallery" tagline="checkout our past adventures" />
      <Topography>
        <Container>
          <Gallery photos={photos} />
        </Container>
      </Topography>
    </Layout>

  )
}


export const query = graphql`
query {
  allPrismicGallery {
    nodes {
      data {
        image_gallery {
          image {
            url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
}
`

export default Grid
